import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const StyledButton = styled.a`
  text-decoration: none;
  font-family: 'Baloo', sans serif;
  background-color: ${ ({ theme, color }) => theme.color[color] };
  cursor: pointer;
  color: ${ ({ theme }) => theme.color.white };
  font-size: ${ ({ size }) => size === 'M' ? 20 : 16 }px;
  padding: ${ ({ size }) => size === 'M' ? '4px 16px' : '2px 16px' };
  border: 1px solid ${ ({ theme, color }) => theme.color[color] };
  border-radius: ${ ({ size }) => size === 'M' ? 8 : 20 }px;
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: ${ ({ size }) => size === 'M' ? 28 : 22 }px;
  }
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const CTAButton = ({ children, size, color, ...rest }) => (
  <StyledButton
    // type="button"
    size={size}
    color={color}
    {...rest}
  >
    {children}
  </StyledButton>
);

CTAButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  size: PropTypes.oneOf(['S', 'M']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  // Plus all native button props
};

CTAButton.defaultProps = {
  size: 'M',
  color: 'primary',
};

export default CTAButton;
