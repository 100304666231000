import PropTypes from 'prop-types';
import styled from 'styled-components';

const Divider = styled.div`
  border-bottom:
    ${ ({ row }) => (row ? 0 : 2) }px
    solid
    ${ ({ theme, row, color }) => (row ? theme.color.transparent : theme.color[`${ color }Light`]) };
  border-right:
    ${ ({ row }) => (row ? 2 : 0) }px
    solid
    ${ ({ theme, row, color }) => (row ? theme.color[`${ color }Light`] : theme.color.transparent) };
`;

Divider.propTypes = {
  row: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

Divider.defaultProps = {
  row: false,
  color: 'secondary',
};

export default Divider;
