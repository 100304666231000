import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Divider from '../divider';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const StyledButton = styled.button`
  font-family: 'Open Sans', sans-serif;
  background-color: ${ ({ theme }) => theme.color.transparent };
  cursor: pointer;
  color: ${ ({ theme }) => theme.color.white };
  font-weight: 1000;
  font-size: 18px;
  padding: 4px 8px;
  text-decoration: none;
  border: none;
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const BurgerButton = ({ children, ...rest }) => (
  <StyledButton
    type="button"
    {...rest}
  >
    <div style={{ width: '50px' }}>
      <Divider className="my1" color="primary" />
      <Divider className="my1" color="primary" />
      <Divider className="my1" color="primary" />
    </div>
  </StyledButton>
);

BurgerButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  // Plus all native button props
};

export default BurgerButton;
