import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const H1 = styled.h1`
  font-family: 'Baloo', sans serif;
  font-size: 34px;
  line-height: 34px;
  font-weight: 400;
  color: ${ ({ theme, color }) => theme.color[color] };
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 45px;
    line-height: 45px;
  }
`;
// -----------------------------------------------------------------------------
const MLP = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: ${ ({ theme, color }) => theme.color[color] };
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 30px;
    line-height: 30px;
  }
`;
// -----------------------------------------------------------------------------
const P = styled.p`
  font-size: 19px;
  font-weight: 600;
  color: ${ ({ theme, color }) => theme.color[color] };
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 26px;
    line-height: 26px;
  }
`;
// -----------------------------------------------------------------------------
const SP = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${ ({ theme, color }) => theme.color[color] };
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 22px;
    line-height: 22px;
  }
`;
// -----------------------------------------------------------------------------
const XSP = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: ${ ({ theme, color }) => theme.color[color] };
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 405px) {
    font-size: 18px;
    line-height: 18px;
  }
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Text = ({ children, size, ...rest }) => {
  if (size === 'L') return <H1 {...rest}>{children}</H1>;
  if (size === 'ML') return <MLP {...rest}>{children}</MLP>;
  if (size === 'M') return <P {...rest}>{children}</P>;
  if (size === 'S') return <SP {...rest}>{children}</SP>;
  // Falback
  return <XSP {...rest}>{children}</XSP>;
};

Text.propTypes = {
  size: PropTypes.oneOf(['L', 'ML', 'M', 'S', 'XS']),
  color: PropTypes.oneOf(['primary', 'secondary']),
};

Text.defaultProps = {
  size: 'M',
  color: 'primary',
};

export default Text;
