import React from 'react';
import PropTypes from 'prop-types';
import CTAButton from '../cta-button';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const CTA = ({ label, ...rest }) => (
  <CTAButton
    // secondary
    href="https://app.mypetdigs.com"
    {...rest}
  >
    {label}
  </CTAButton>
);

CTA.propTypes = {
  label: PropTypes.string,
};

CTA.defaultProps = {
  label: 'Sign up',
};

export default CTA;
