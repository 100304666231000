import colors from './colors';
import fonts from './fonts';

const fts = fonts();

const fontSizes = {};

Object.keys(fts).forEach((key) => {
  fontSizes[key] = fts[key].fontSize;
});

// styled-components theme
const theme = {
  color: colors,
  fontSize: fontSizes,
};

export default theme;
