import colors from './colors';

// TODO: use utility funtion to determine fontWeight based on the given arg
const fonts = (color = colors.primary, fontWeight = 'normal') => ({
  XL: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    fontSize: 46,
    color: colors.white,
    textShadow: `-1px -1px 0 ${color}, 1px -1px 0 ${color}, -1px 1px 0 ${color}, 1px 1px 0 ${color}`,
    tag: 'h1',
  },
  L: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    fontSize: 32,
    color,
    tag: 'h2',
  },
  ML: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    fontSize: 22,
    lineHeight: '32px',
    color,
    tag: 'h3',
  },
  M: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    fontSize: 19,
    lineHeight: '25px',
    color,
    tag: 'h4',
  },
  SM: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: fontWeight === 'normal' ? 400 : 700,
    fontSize: 17,
    lineHeight: '24px',
    color,
    tag: 'p',
  },
  S: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: fontWeight === 'normal' ? 400 : 700,
    fontSize: 14,
    lineHeight: '18px',
    color,
    tag: 'p',
  },
});

export default fonts;
