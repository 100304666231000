/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import 'typeface-open-sans';
import 'typeface-baloo';
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import scTheme from '../../theme/sc';
import Header from '../header';
import './sanitize.css';
import './basscss.min.css';
import './style.css';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const Footer = styled.footer`
  background-color: ${ ({ theme }) => theme.color.secondary };
  color: ${ ({ theme }) => theme.color.white };
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={scTheme}>
        <React.Fragment>
          <Header location={location} />
          <div className="mx-auto p0">
            <main>{children}</main>
            <Footer className="flex justify-around flex-wrap p2">
              <span className="block px2">Copyright 2019 Pet Digs</span>
              <span className="block px2">contact&#64;petdigs.com</span>
            </Footer>
          </div>
        </React.Fragment>
      </ThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
