const colors = {
  primary: '#004C62',
  secondary: '#E2550D',
  error: '#ff6347',

  black: 'black',
  white: 'white',
  transparent: 'transparent',
  gray: 'gray',

  errorLight: '#fcdbd9',
  success: 'green',
  successLight: '#cfefdf',
  link: 'blue',
  blueDark: 'rgb(0,76,98)',
  grayLight: 'rgb(240,240,240)',
  grayExtraLight: 'rgb(248, 248, 248)',
  blueLight: 'rgb(122,182,194)',
  orangeLight: '#EAA480',
  primaryLight: '#004C62', // same as primary
  secondaryLight: '#f3bfac',
};

export default colors;
