import React from 'react';
import PropTypes from 'prop-types';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Section = ({
  children,
  viewType,
  className,
  ...rest
}) => (
  <section
    className={`mx-auto px2 ${ viewType === 'mobile' ? 'mobile-width' : 'desktop-width' } ${ className }`}
    {...rest}
  >
    {children}
  </section>
);

Section.propTypes = {
  viewType: PropTypes.oneOf(['mobile', 'desktop']).isRequired,
  className: PropTypes.string,
};

Section.defaultProps = {
  className: '',
};

export default Section;
