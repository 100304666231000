import React, { useState } from 'react';
import { Link } from 'gatsby';
import colors from '../theme/colors';
import ImageLogo from './image-logo';
import CTA from './cta';
import BurgerButton from './burger-button';
import Text from './text';

// -----------------------------------------------------------------------------
// CONSTANTS:
// -----------------------------------------------------------------------------
const ROUTES = [
  { label: 'PET OWNERS', path: '/' },
  { label: 'PET SITTERS', path: '/sitters' },
  { label: 'ABOUT', path: '/about' },
  { label: 'FAQ', path: '/faq' },
];
// -----------------------------------------------------------------------------
// STYLE:
// -----------------------------------------------------------------------------
const linkStyle = {
  textDecoration: 'none',
  fontWeight: 'bold',
  color: colors.primary,
};
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Header = ({ location }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      {/* MOBILE */}
      <header
        className="z2 fixed top-0 right-0 bottom-0 left-0 header-height hide-for-large"
        style={{ backgroundColor: colors.white }}
      >
        <div className="px2 header-height flex items-center justify-between">
          <Link
            to="/"
            className="block"
            style={linkStyle}
          >
            <ImageLogo style={{ width: 270 }} />
          </Link>

          <BurgerButton onClick={() => { setOpen(!isOpen); }} children={''}/>
        </div>
      </header>
      {isOpen && (
        <div
          className="z2 fixed right-0 bottom-0 left-0 hide-for-large"
          style={{
            top: '90px',
            backgroundColor: colors.white,
            borderTop: `1px solid ${ colors.secondaryLight }`,
            height: 452,
          }}
        >
          <div className="flex flex-column items-end">
            {ROUTES.map(({ label, path }) => (
              <Link
                key={label}
                to={path}
                className="block"
                style={{
                  ...linkStyle,
                  width: '100%',
                  borderBottom: `1px solid ${ colors.secondaryLight }`,
                }}
              >
                <Text
                  size="S"
                  className="p3 right-align m0"
                  color={location.pathname === path ? 'secondary' : 'primary'}
                >
                  {label}
                </Text>
              </Link>
            ))}

            <div
              className="p3 flex justify-end"
              // style={{
              //   width: '100%',
              //   borderBottom: `1px solid ${ colors.secondaryLight }`,
              // }}
            >
              <CTA label="JOIN" size="S" color="secondary" />
            </div>
          </div>
        </div>
      )}

      {/* DESKTOP */}
      <header
        className="z2 fixed top-0 right-0 bottom-0 left-0 header-height show-for-large"
        style={{ backgroundColor: colors.white }}
      >
        <div className="px2 header-height flex items-center justify-between">
          <Link
            to="/"
            className="block"
            style={linkStyle}
          >
            <ImageLogo style={{ width: 270 }} />
          </Link>

          <div className="flex items-center justify-between">
            {ROUTES.map(({ label, path }) => (
              <Link
                key={label}
                to={path}
                className="block mx2"
                style={{ ...linkStyle, color: location.pathname === path ? colors.secondary : colors.primary }}
              >
                {label}
              </Link>
            ))}

            <CTA label="JOIN" size="S" color="secondary" />

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
